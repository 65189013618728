import React from 'react';
import { Accordion } from 'react-bootstrap';
import clsx from 'clsx';
import { NumericFormatField } from '../../Forms/Fields';
import { AssetAdvisoryFormValues, useAssetAdvisoryAccordions } from './useAssetAdvisoryForm';

export function ObligationsAccordion() {
  const {
    isObligationsAccordionFilled,
  } = useAssetAdvisoryAccordions();

  return (
    <Accordion.Item className="shadow-none" eventKey="2">
      <Accordion.Button
        className={clsx(isObligationsAccordionFilled && 'bg-success-light')}
      >
        Obligaciones
      </Accordion.Button>

      <Accordion.Body
        className="d-flex flex-column gap-2"
      >
        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_mortgage_credit"
          path="clp_mortgage_credit"
          label="¿Tienes alguna obligación de Crédito Hipotecario nacional o internacional?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="uf_mortgage_credit"
          path="uf_mortgage_credit"
          prefix="UF "
          placeholder="UF"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_mortgage_credit"
          path="usd_mortgage_credit"
          prefix="USD "
          placeholder="USD"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_current_consumer_credit"
          path="clp_current_consumer_credit"
          label="¿Tienes un crédito de consumo vigente?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_current_consumer_credit"
          path="usd_current_consumer_credit"
          prefix="USD "
          placeholder="USD"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_other_obligations"
          path="clp_other_obligations"
          label="¿Cuánto tienes en otras obligaciones que no se hayan mencionado anteriormente?"
          prefix="$ "
          placeholder="$"
          helpPopover={{
            id: 'other-obligations-help-popover',
            width: 362,
            renderContent: () => (
              <div className="d-flex align-items-start gap-2 p-1">
                <img src="/static/img/icons/help-light.svg" width={48} alt="" />

                <section>
                  <header>
                    <h6 className="fs-base fw-bold text-white mb-1">Otras obligaciones</h6>
                  </header>

                  <div className="text-white">
                    Aquí puedes preguntar por leasing, actividades como independiente entre otros.
                  </div>
                </section>
              </div>
            ),
          }}
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="uf_other_obligations"
          path="uf_other_obligations"
          prefix="UF "
          placeholder="UF"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_other_obligations"
          path="usd_other_obligations"
          prefix="USD "
          placeholder="USD"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
