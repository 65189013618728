import React from 'react';
import { Accordion } from 'react-bootstrap';
import clsx from 'clsx';
import { NumericFormatField } from '../../Forms/Fields';
import { AssetAdvisoryFormValues, useAssetAdvisoryAccordions } from './useAssetAdvisoryForm';

export function OtherAssetsAccordion() {
  const {
    isOtherAssetsAccordionFilled,
  } = useAssetAdvisoryAccordions();

  return (
    <Accordion.Item className="shadow-none" eventKey="1">
      <Accordion.Button className={clsx(isOtherAssetsAccordionFilled && 'bg-success-light')}>
        Otros activos
      </Accordion.Button>

      <Accordion.Body className="d-flex flex-column gap-2">
        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_pension_plan"
          path="clp_pension_plan"
          label="¿Tienes planes de pensiones en Chile y/o en el extranjero?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_pension_plan"
          path="usd_pension_plan"
          prefix="USD "
          placeholder="USD"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_real_state_assets"
          path="clp_real_state_assets"
          label="¿Tienes bienes Inmobiliarios nacionales o internacionales?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="uf_real_state_assets"
          path="uf_real_state_assets"
          prefix="UF "
          placeholder="UF"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_real_state_assets"
          path="usd_real_state_assets"
          prefix="USD "
          placeholder="USD"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="private_company_stakes"
          path="private_company_stakes"
          label="¿En cuánto está valorizada tu participación en algún negocio o empresa privada?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_other_investments"
          path="clp_other_investments"
          label="¿Cuánto tienes en otros activos no mencionados anteriormente?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="uf_other_investments"
          path="uf_other_investments"
          prefix="UF "
          placeholder="UF"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_other_investments"
          path="usd_other_investments"
          prefix="USD "
          placeholder="USD"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
