/* eslint-disable max-classes-per-file */
import React from 'react';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import { GraphUpIcon } from '../components/icons/GraphUpIcon';
import { MoneyBagIcon } from '../components/icons/MoneyBagIcon';
import { WalletMoneyIcon } from '../components/icons/WalletMoneyIcon';
import { ButtonRadioOption } from '../components/Forms/Fields/ButtonRadioField';
import { CheckboxOption } from '../components/Forms/Fields/CheckboxField';
import { RadioBoxOption } from '../components/Forms/Fields/RadioBoxField';
import { RadioCardOption } from '../components/Forms/Fields/RadioCardField';
import { RadioOption } from '../components/Forms/Fields/RadioField';
import { SelectOption } from '../components/Forms/Fields/SelectField';
import { formatPercentage } from './formatters';
import { getUserContext } from './user';

interface WithPickOptions<T extends string = string> {
  options?: T[]
}

export const GenderOptions = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
} as const;

export type GenderOptionType = keyof typeof GenderOptions;
export class Genders {
  static asRadioOptions<T extends FieldValues>(
    { omit }: { omit: Array<keyof typeof GenderOptions> } = { omit: [] }):
    RadioOption<T>[] {
    const radioOptions: RadioOption<T>[] = [{
      id: 'male',
      label: 'Hombre',
      value: GenderOptions.MALE,
    },
    {
      id: 'female',
      label: 'Mujer',
      value: GenderOptions.FEMALE,
    },
    {
      id: 'other',
      label: 'Prefiero no decirlo',
      value: GenderOptions.OTHER,
    }];

    return radioOptions.filter(({ value }) => !omit
      .includes(value as keyof typeof GenderOptions)
    );
  }
}

export const CompensationPlanOptions = {
  A: 'A',
  B: 'B',
} as const;
export class CompensationPlan {
  static asRadioOptions<T extends FieldValues>() {
    const radioOptions: RadioOption<T>[] = [
      {
        id: 'compensation_a',
        label: 'Plan A',
        value: CompensationPlanOptions.A,
      },
      {
        id: 'compensation_b',
        label: 'Plan B',
        value: CompensationPlanOptions.B,
      },
    ];

    return radioOptions;
  }
}

export type ExtraCompensationInsuredOptions = 'accidental_death' | 'itp' | 'several_diseases';
export class ExtraCompensationInsured {
  static asCheckboxOptions({ options }: WithPickOptions<ExtraCompensationInsuredOptions> = {}) {
    const checkboxesOptions: CheckboxOption<ExtraCompensationInsuredOptions>[] = [
      {
        id: 'accidental_death',
        label: 'Muerte accidental',
      },
      {
        id: 'itp',
        label: 'ITP 2/3',
      },
      {
        id: 'several_diseases',
        label: 'Enfermedades graves',
      },
    ];

    if (options) {
      return checkboxesOptions.filter(({ id }) => options.includes(id));
    }

    return checkboxesOptions;
  }
}

export type DownloadDocsOptions = 'infograph' | 'projection_table';
export class DownloadDocs {
  static asCheckboxOptions({ options }: WithPickOptions<DownloadDocsOptions> = {}) {
    const checkboxesOptions: CheckboxOption<DownloadDocsOptions>[] = [
      {
        id: 'infograph',
        label: 'Simulación',
      },
      {
        id: 'projection_table',
        label: 'Tabla de proyección',
      },
    ];

    if (options) {
      return checkboxesOptions.filter(({ id }) => options.includes(id));
    }

    return checkboxesOptions;
  }
}

export const RegimeOptions = {
  A: 'A',
  B: 'B',
} as const;

export type Regime = keyof typeof RegimeOptions;
export class Regimes {
  static asRadioCardOptions<T extends FieldValues>() {
    const ICON_SIZES = {
      width: 24,
      height: 24,
    };

    const radioCardOptions: RadioCardOption<T>[] = [
      {
        id: 'regime_a',
        label: 'Régimen A',
        value: RegimeOptions.A,
        iconOptions: {
          ...ICON_SIZES,
          src: '/static/img/icons/apv-regimea.svg',
          alt: 'regime a',
        },
      },
      {
        id: 'regime_b',
        label: 'Régimen B',
        value: RegimeOptions.B,
        iconOptions: {
          ...ICON_SIZES,
          src: '/static/img/icons/apv-regimeb.svg',
          alt: 'regime b',
        },
      },
    ];

    return radioCardOptions;
  }
}

export class SevereDiseaseAssuranceRate {
  static asSelectOptions<T extends FieldValues>() {
    const selectOptions: SelectOption<T>[] = [
      {
        label: '10%',
        value: 10 as PathValue<T, Path<T>>,
      },
      {
        label: '20%',
        value: 20 as PathValue<T, Path<T>>,
      },
      {
        label: '30%',
        value: 30 as PathValue<T, Path<T>>,
      },
      {
        label: '40%',
        value: 40 as PathValue<T, Path<T>>,
      },
    ];

    return selectOptions;
  }
}

const RiskProfileOption = {
  very_conservative: 'very_conservative',
  conservative: 'conservative',
  moderate: 'moderate',
  aggressive: 'aggressive',
  very_aggressive: 'very_aggressive',
} as const;

type RiskProfile = keyof typeof RiskProfileOption;

type SummarizedRiskProfiles = Extract<RiskProfile, 'conservative' | 'moderate' | 'aggressive'>;

type RiskProfilesMode = 'apv' | 'ffmm';

export class RiskProfiles {
  static ffmmPercentages: Record<SummarizedRiskProfiles, number> = {
    conservative: 0.4,
    moderate: 2.1,
    aggressive: 3.0,
  };

  static apvPercentages: Record<SummarizedRiskProfiles, number> = {
    conservative: 1.4,
    moderate: 3.31,
    aggressive: 4.87,
  };

  static asSelectOptions<T extends FieldValues>() {
    const defaultChoice: SelectOption<T> = {
      label: 'Balanceado',
      value: RiskProfileOption.moderate as PathValue<T, Path<T>>,
    };

    const selectOptions: SelectOption<T>[] = [
      {
        label: 'Conservador',
        value: RiskProfileOption.conservative as PathValue<T, Path<T>>,
      },

      defaultChoice,

      {
        label: 'Agresivo',
        value: RiskProfileOption.aggressive as PathValue<T, Path<T>>,
      },
    ];

    return {
      choices: selectOptions,
      defaultChoice,
    };
  }

  static asSelectOptionsWithPercentage<T extends FieldValues>(mode: RiskProfilesMode) {
    const { choices, defaultChoice } = this.asSelectOptions();
    const percentagesMap = mode === 'apv' ? this.apvPercentages : this.ffmmPercentages;

    const choicesWithPercentages = choices.map((option) => ({
      ...option,
      label: `
        ${option.label}: ${formatPercentage(
        percentagesMap[option.value as SummarizedRiskProfiles], { withFractionDigits: true })}`,
    }));

    const defaultChoiceWithPercentage = {
      ...defaultChoice,
      label: `${defaultChoice.label}: ${formatPercentage(
        percentagesMap[defaultChoice.value as SummarizedRiskProfiles], { withFractionDigits: true })}`,
    };

    return {
      choices: choicesWithPercentages as SelectOption<T>[],
      defaultChoice: defaultChoiceWithPercentage as SelectOption<T>,
    };
  }
}

export class InsurancePlans {
  static asSelectOptions<T extends FieldValues>() {
    const defaultChoice: SelectOption<T> = {
      label: 'Plan Seguro',
      value: 'safe_plan' as PathValue<T, Path<T>>,
    };

    const selectOptions: SelectOption<T>[] = [
      defaultChoice,
      {
        label: 'Plan Más Seguro',
        value: 'safer_plan' as PathValue<T, Path<T>>,
      },
      {
        label: 'Plan Mega Seguro',
        value: 'mega_safe_plan' as PathValue<T, Path<T>>,
      },
    ];

    return { choices: selectOptions, defaultChoice };
  }
}

export class MutualFundsGoals {
  static asSelectOptions<T extends FieldValues>() {
    const selectOptions: SelectOption<T>[] = [
      {
        label: 'Viaje',
        value: 'TRAVEL' as PathValue<T, Path<T>>,
      },
      {
        label: 'Propiedad',
        value: 'PROPERTY' as PathValue<T, Path<T>>,
      },
      {
        label: 'Proyecto',
        value: 'PROJECT' as PathValue<T, Path<T>>,
      },
      {
        label: 'Educación',
        value: 'EDUCATION' as PathValue<T, Path<T>>,
      },
      {
        label: 'Otro',
        value: 'OTHER' as PathValue<T, Path<T>>,
      },
    ];

    return selectOptions;
  }
}

export const DISCOVER_INVESTMENT_PROFILE_URL = 'https://banco.bice.cl/inversiones/aprende-a-invertir/conoce-tu-perfil';

export const InvestmentProfileOptions = {
  conservative: 'CONSERVATIVE',
  balanced: 'BALANCED',
  aggressive: 'AGGRESSIVE',
} as const;

export type InvestmentProfileKeys = keyof typeof InvestmentProfileOptions;
export type InvestmentProfileValues = typeof InvestmentProfileOptions[InvestmentProfileKeys];

export class InvestmentProfiles {
  static asRadioOptions<T extends FieldValues>() {
    const radioOptions: RadioOption<T>[] = [
      {
        id: 'conservative',
        label: 'Conservador',
        value: InvestmentProfileOptions.conservative as PathValue<T, Path<T>>,
      },
      {
        id: 'balanced',
        label: 'Balanceado',
        value: InvestmentProfileOptions.balanced as PathValue<T, Path<T>>,
      },
      {
        id: 'aggresive',
        label: 'Agresivo',
        value: InvestmentProfileOptions.aggressive as PathValue<T, Path<T>>,
      },
    ];

    return radioOptions;
  }
}

export class RecommendationObjectives {
  static asRadioOptions<T extends FieldValues>() {
    const radioOptions: ButtonRadioOption<T>[] = [
      {
        id: 'longevity',
        label: (
          <>
            <GraphUpIcon />
            Longevidad
          </>
        ),
        value: 'LONGEVITY' as PathValue<T, Path<T>>,
        className: 'btn-radio-secondary',
      },
      {
        id: 'liquidity',
        label: (
          <>
            <WalletMoneyIcon />
            Liquidez
          </>
        ),
        value: 'LIQUIDITY' as PathValue<T, Path<T>>,
        className: 'btn-radio-info',
      },
      {
        id: 'inheritance',
        label: (
          <>
            <MoneyBagIcon />
            Herencia
          </>
        ),
        value: 'INHERITANCE' as PathValue<T, Path<T>>,
        className: 'btn-radio-primary',
      },
    ];

    return radioOptions;
  }
}

export const LongevityObjectivesOptions = {
  assetPreservation: 'ASSET_PRESERVATION',
  assetAccumulation: 'ASSET_ACCUMULATION',
  assetGrowth: 'ASSET_GROWTH',
  retirement: 'RETIREMENT',
} as const;

export class LongevityObjectives {
  static asRadioOptions<T extends FieldValues>() {
    const radioOptions: RadioOption<T>[] = [
      {
        id: LongevityObjectivesOptions.assetPreservation,
        label: 'Preservación Patrimonial',
        helpText: (
          <>
            <h4 className="title">Preservación Patrimonial</h4>
            <p className="mb-0">
              <span className="fw-bold">Riesgo Bajo</span>
              . Enfoque en proteger y mantener el valor del patrimonio existente.
              La prioridad es minimizar el riesgo y evitar pérdidas,
              por lo que se invierte en activos seguros y estables.
            </p>
          </>
        ),
        value: LongevityObjectivesOptions.assetPreservation as PathValue<T, Path<T>>,
      },
      {
        id: LongevityObjectivesOptions.assetAccumulation,
        label: 'Acumulación Patrimonial',
        helpText: (
          <>
            <h4 className="title">Acumulación Patrimonial</h4>
            <p className="mb-0">
              <span className="fw-bold">Riesgo Medio</span>
              . Busca aumentar el patrimonio a un ritmo moderado, equilibrando el riesgo
              y el rendimiento. El objetivo es crecer el patrimonio de manera constante
              y sostenible a lo largo del tiempo.
            </p>
          </>
        ),
        value: LongevityObjectivesOptions.assetAccumulation as PathValue<T, Path<T>>,
      },
      {
        id: LongevityObjectivesOptions.assetGrowth,
        label: 'Crecimiento Patrimonial',
        helpText: (
          <>
            <h4 className="title">Crecimiento Patrimonial</h4>
            <p className="mb-0">
              <span className="fw-bold">Riesgo Alto</span>
              . Orientado a maximizar el crecimiento del patrimonio,
              aceptando un mayor nivel de riesgo. El objetivo es lograr
              un crecimiento significativo del patrimonio, aunque esto implique
              la posibilidad de fluctuaciones y pérdidas a corto plazo.
            </p>
          </>
        ),
        value: LongevityObjectivesOptions.assetGrowth as PathValue<T, Path<T>>,
      },
      {
        id: LongevityObjectivesOptions.retirement,
        label: 'Jubilación',
        value: LongevityObjectivesOptions.retirement as PathValue<T, Path<T>>,
      },
    ];

    return radioOptions;
  }
}

/**
 * Stringified booleans representations are needed to handle boolean choices in inputs
 * which uses the `value` property as source of truth for the user interaction.
 * `value` property doesn't raw `boolean` values, although it just
 * accepts `string | number | string[] | undefined` as types
 * Since we need to manage `boolean` values in inputs like radio buttons, so we define
 * a manual stringified boolean object which provides stringified boolean representations
 */
export const StringifiedBooleans = {
  TRUE: 'TRUE',
  FALSE: 'FALSE',
} as const;

export class BooleanOptions {
  static asRadioOptions<T extends FieldValues>(mode: 'lowercase' | 'uppercase' = 'uppercase') {
    const radioOptions: RadioOption<T>[] = [
      {
        id: StringifiedBooleans.FALSE,
        label: 'No',
        value: mode === 'uppercase' ? StringifiedBooleans.FALSE : StringifiedBooleans.FALSE.toLowerCase(),
      },
      {
        id: StringifiedBooleans.TRUE,
        label: 'Sí',
        value: mode === 'uppercase' ? StringifiedBooleans.TRUE : StringifiedBooleans.TRUE.toLowerCase(),
      },
    ];

    return radioOptions;
  }
}

export const ApvTransferOptions = {
  NONE: 'NONE',
  TRANSFER: 'TRANSFER',
} as const;

export class APVTransfer {
  static asRadioOptions<T extends FieldValues>() {
    const radioOptions: RadioOption<T>[] = [
      {
        id: ApvTransferOptions.NONE,
        label: 'No',
        value: ApvTransferOptions.NONE,
      },
      {
        id: ApvTransferOptions.TRANSFER,
        label: 'Traspaso',
        value: ApvTransferOptions.TRANSFER,
      },
    ];

    return radioOptions;
  }
}

export const SavingsOption = {
  savings_capacity: 'savings_capacity',
  ideal_pension: 'ideal_pension',
  goal_value: 'goal_value',
  university_value: 'university_value',
} as const;
export class SavingsTypeOptions {
  static asRadioBoxOptions<T extends FieldValues>(
    { select }: { select: Array<keyof typeof SavingsOption> } = { select: [] }
  ) {
    const radioOptions: RadioBoxOption<T>[] = [
      {
        id: SavingsOption.savings_capacity,
        label: 'Capacidad de ahorro',
        value: SavingsOption.savings_capacity,
      },
      {
        id: SavingsOption.ideal_pension,
        label: 'Pensión ideal',
        value: SavingsOption.ideal_pension,
      },
      {
        id: SavingsOption.goal_value,
        label: 'Valor de tu meta',
        value: SavingsOption.goal_value,
      },
      {
        id: SavingsOption.university_value,
        label: 'Valor universidad',
        value: SavingsOption.university_value,
      },
    ];

    return radioOptions.filter(({ value }) => select
      .includes(value as keyof typeof SavingsOption)
    );
  }
}

export const COMPANIES = {
  INVESTMENTS: 'BICE Investments',
  LIFE: 'BICE Life',
} as const;

export type Company = keyof typeof COMPANIES;

export const INVESTMENTS_PRODUCTS = {
  APV: 'APV',
  STOCKS: 'Stocks',
  MUTUAL_FUNDS: 'Mutual Funds',
} as const;

export const LIFE_PRODUCTS = {
  APV_LIFE: 'APV Life',
  FULL_FLEXIBLE: 'Full Flexible',
  FULL_FLEXIBLE_UNIVERSITY: 'Full Flexible University',
  PRIVATE_RENT: 'Private Rent',
  LIFE_RENT: 'Life Rent',
} as const;

export type CompanyProduct = keyof typeof INVESTMENTS_PRODUCTS | keyof typeof LIFE_PRODUCTS;

export class CompaniesOptions {
  static asRadioBoxOptions<T extends FieldValues>() {
    const radioOptions: RadioBoxOption<T>[] = [
      {
        id: COMPANIES.INVESTMENTS,
        label: 'BICE Inversiones',
        value: COMPANIES.INVESTMENTS,
      },
      {
        id: COMPANIES.LIFE,
        label: 'BICE Vida',
        value: COMPANIES.LIFE,
      },
    ];

    return radioOptions;
  }
}

export class CompaniesProducts {
  static asSelectOptions<T extends FieldValues>(company: typeof COMPANIES[Company]) {
    const productsOptions: Record<typeof COMPANIES[Company], SelectOption<T>[]> = {
      [COMPANIES.INVESTMENTS]: [
        {
          label: 'APV',
          value: INVESTMENTS_PRODUCTS.APV as PathValue<T, Path<T>>,
        },
        {
          label: 'Acciones',
          value: INVESTMENTS_PRODUCTS.STOCKS as PathValue<T, Path<T>>,
        },
        {
          label: 'Fondos Mutuos',
          value: INVESTMENTS_PRODUCTS.MUTUAL_FUNDS as PathValue<T, Path<T>>,
        },
      ],
      [COMPANIES.LIFE]: [
        {
          label: 'APV Life',
          value: LIFE_PRODUCTS.APV_LIFE as PathValue<T, Path<T>>,
        },
        {
          label: 'Seguro Full Flexible',
          value: LIFE_PRODUCTS.FULL_FLEXIBLE as PathValue<T, Path<T>>,
        },
        {
          label: 'Seguro Full Flexible Universitario',
          value: LIFE_PRODUCTS.FULL_FLEXIBLE_UNIVERSITY as PathValue<T, Path<T>>,
        },
        {
          label: 'Rentas Privadas',
          value: LIFE_PRODUCTS.PRIVATE_RENT as PathValue<T, Path<T>>,
        },
        {
          label: 'Rentas Vitalicias',
          value: LIFE_PRODUCTS.LIFE_RENT as PathValue<T, Path<T>>,
        },
      ],
    };

    return productsOptions[company];
  }
}

// TODO: should be extended as a generic time options generator?
export class DeriveCustomerTimeOptions {
  static asSelectOptions<T extends FieldValues>() {
    const availableHours = [
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
    ];

    const timeOptions: SelectOption<T>[] = availableHours.map((hour) => ({
      label: hour,
      value: hour as PathValue<T, Path<T>>,
    }));

    return timeOptions;
  }
}

export const AdditionalSavingsOptions = {
  APV: 'apv',
  INSURANCES: 'insurances',
  INVESTMENTS: 'investments',
};

export type AdditionalSavingsOptionsKey = keyof typeof AdditionalSavingsOptions;
export type AdditionalSavingsOptionsType = typeof AdditionalSavingsOptions[
  AdditionalSavingsOptionsKey
];

export class AdditionalSavings {
  static asSelectOptions<T extends FieldValues>() {
    const options: SelectOption<T>[] = [
      {
        label: 'APV',
        value: AdditionalSavingsOptions.APV as PathValue<T, Path<T>>,
      },
      {
        label: 'Seguro',
        value: AdditionalSavingsOptions.INSURANCES as PathValue<T, Path<T>>,
      },
      {
        label: 'Inversiones',
        value: AdditionalSavingsOptions.INVESTMENTS as PathValue<T, Path<T>>,
      },
    ];

    return options;
  }

  static asValues() {
    return Object.values(AdditionalSavingsOptions);
  }
}

export const CurrencyOptions = {
  CLP: 'clp',
  CLF: 'clf',
} as const;

export type CurrencyOptionKey = keyof typeof CurrencyOptions;
export type CurrencyOptionType = typeof CurrencyOptions[CurrencyOptionKey];

export class Currency {
  static transform(amount: number, target: CurrencyOptionKey) {
    const ufRatio = getUserContext('UF_VALUE') as number;

    if (target === 'CLF') {
      return amount / ufRatio;
    }

    return amount * ufRatio;
  }

  static asSelectOptions<T extends FieldValues>() {
    const options: SelectOption<T>[] = [
      {
        label: 'CLP',
        value: CurrencyOptions.CLP as PathValue<T, Path<T>>,
      },
      {
        label: 'CLF',
        value: CurrencyOptions.CLF as PathValue<T, Path<T>>,
      },
    ];

    const [defaultOption] = options;

    return {
      options,
      defaultOption,
    };
  }
}

export type ExtraordinaryContributionPeriodicity = 'UNIQUE' | 'ANNUAL';

export const ExtraordinaryContributionOptions = {
  UNIQUE: 'UNIQUE',
  ANNUAL: 'ANNUAL',
} as const;

export type ExtraordinaryContributionKey = keyof typeof ExtraordinaryContributionOptions;

export class ExtraordinaryContribution {
  static asRadioOptions<T extends FieldValues>() {
    const radioOptions: RadioOption<T>[] = [
      {
        id: ExtraordinaryContributionOptions.UNIQUE,
        value: ExtraordinaryContributionOptions.UNIQUE,
        label: 'Único',
      },
      {
        id: ExtraordinaryContributionOptions.ANNUAL,
        value: ExtraordinaryContributionOptions.ANNUAL,
        label: 'Anual',
      },
    ];

    return radioOptions;
  }
}

export class FFTimeFrame {
  static asSelectOptions<T extends FieldValues>() {
    const selectOptions: SelectOption<T>[] = [
      {
        label: '5 años',
        value: 5 as PathValue<T, Path<T>>,
      },
      {
        label: '10 años',
        value: 10 as PathValue<T, Path<T>>,
      },
      {
        label: '15 años',
        value: 15 as PathValue<T, Path<T>>,
      },
      {
        label: '20 años',
        value: 20 as PathValue<T, Path<T>>,
      },
    ];

    return selectOptions;
  }
}
