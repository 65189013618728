import React from 'react';
import { Accordion } from 'react-bootstrap';
import clsx from 'clsx';
import { NumericFormatField } from '../../Forms/Fields';
import { AssetAdvisoryFormValues, useAssetAdvisoryAccordions } from './useAssetAdvisoryForm';

export function IncomesAccordion() {
  const {
    isIncomesAccordionFilled,
  } = useAssetAdvisoryAccordions();

  return (
    <Accordion.Item className="shadow-none" eventKey="3">
      <Accordion.Button
        className={clsx(isIncomesAccordionFilled && 'bg-success-light')}
      >
        Ingresos
      </Accordion.Button>

      <Accordion.Body
        className="d-flex flex-column gap-2"
      >

        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_financial_products"
          path="clp_financial_products"
          label="¿Con cuánto cuentas en ingresos provenientes de instrumentos financieros líquidos?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_financial_products"
          path="usd_financial_products"
          prefix="USD "
          placeholder="USD"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="clp_incomes"
          path="clp_incomes"
          label="¿Con cuánto cuentas en ingresos recurrentes?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="uf_incomes"
          path="uf_incomes"
          prefix="UF "
          placeholder="UF"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          id="usd_incomes"
          path="usd_incomes"
          prefix="USD "
          placeholder="USD"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
